import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Stack,
  Grid,
  Paper,
  InputLabel,
  FormLabel,
  Tabs,
  Tab,
  useRadioGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  OutlinedInput,
  CircularProgress,
  Menu,
  ListItem,
  ListItemText,
  Icon,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import moment from "moment";
import SelectFilter from "react-select";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  ReactDataGrid_default,
  showError,
} from "../components/common";

import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import PagingSearchCodeDialog from "../tools/PagingSearchCodeDialog";
import SearchFormColumnsDialog from "../tools/SearchFormColumnsDialog";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";
import ButtonTimKiem from "../components/ButtonTimKiem";
import DataEditDialog from "../templates/DataEditDialog";
import MenuButtons from "../components/MenuButtons";
import MySelectFilter from "../components/MySelectFilter";
import MySelect from "../components/MySelect";
import StorageIcon from "@mui/icons-material/Storage";
import DepartureReportEditDialog from "./DepartureReportEditDialog";
import NoonAtSeaReportEditDialog from "./NoonAtSeaReportEditDialog";
import ArrivalReportEditDialog from "./ArrivalReportEditDialog";
import AllfastReportEditDialog from "./AllfastReportEditDialog";
import NoonAtPortReportEditDialog from "./NoonAtPortReportEditDialog";
import ShiftingReportEditDialog from "./ShiftingReportEditDialog";
import PortLogEditDialog from "./PortLogEditDialog";

import {
  gridColumns_overview,
  gridColumns_overview_groups,
  gridColumns_TieuThu,
  gridColumns_TieuThu_groups,
  gridColumns_Summary,
  gridColumns_Summary_groups,
  gridColumns_Voyage,
  formatNumber,
  warningColumns,
} from "./VoyageDataInGridPage_Grids";
import { format } from "date-fns";
import RevisedRobDialog from "./RevisedRobDialog";
import WarningConfirmDialog from "./WarningConfirmDialog";
import { ThirtyFpsSelect } from "@mui/icons-material";
import WarningListDialog from "./WarningListDialog";
import ListReportDataDialog from "./ListReportDataDialog";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
      VoyageCode: "Voyage number:",
      TripCode: "Voyage:",
      ReportTypeID: "Report type:",
      ReportTime: "Date:",
      Mode: "Mode:",
      DataScope: "View:",
      DataDisplay0: "List",
      DataDisplay1: "Total",
    }
  : {
      VoyageCode: "Số chuyến đi:",
      TripCode: "Voyage:",
      ReportTypeID: "Loại báo cáo:",
      ReportTime: "Ngày:",
      Mode: "Hiển thị",
      DataScope: "View:",
      DataDisplay0: "List",
      DataDisplay1: "Total",
    };

const buttonLabels = englishUI
  ? {
      btnTimKiem: "Search",
      btnLoadConfig: "Load config",
      btnConfig: "Save config",
      btnAtSea: "At-Sea",
      btnReportData: "All",
      btnInport: "In-port",
      btnManu: "Manu",
      btnPortLog: "Port Log",
      btnRevisedRob: "Revised ROB",
      btnWarning: "Confirm",
    }
  : {
      btnTimKiem: "Tìm kiếm",
      btnLoadConfig: "Cấu hình",
      btnConfig: "Ghi cấu hình",
      btnAtSea: "At-Sea",
      btnReportData: "All",
      btnInport: "In-port",
      btnManu: "Manu",
      btnPortLog: "Port Log",
      btnRevisedRob: "Revised ROB",
      btnWarning: "Confirm",
    };
/*-----------------------Dinh nghia cac column trong grid -----------------*/

/*-----------------------Cac bien su dung trong form ----------------------*/
const danhsach_DataScope = [
  {
    value: "Trip",
    label: "Trip",
  },
  {
    value: "Voyage",
    label: "Voyage",
  },
];
const downloadBlob = (blob, fileName = "grid-data.csv") => {
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);

  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  link.style.position = "absolute";
  link.style.visibility = "hidden";

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};
////////////////////////////////-----------------------------------////////////////////////////////////
class VoyageDataInGridPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  allGrids = {}; //danh sach grid
  constructor(props) {
    super(props);
    this.doExport = this.doExport.bind(this);
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: { DM_Voyage: [], DM_VoyageTrip: [], DM_ReportType: [] }, //luu cac du lieu danh muc su dung trong form
      DanhSachNgay: [],
      NgayChon: undefined, //ngay thang duoc chon
      //searching
      sheetLoading: true,
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
              PageIndex: 1,
              PageSize: 20,
              Ascending: true,
              SortBy: 0,
              //danh sach cac tieu chi tim kiem
              VoyageCode: null,
              TripCode: null, //ma hanh trinh
              Ngay: new Date(), //thoi gian la ngay
              DataScope: "Trip", //xem trong ngay, 1 hanh trinh, 1 chuyen
              Mode: "VoyageData", //sheet gi
              DataFilter: "All", //loc theo che do hay tat ca
              DataDisplay: 0, //0 là chi tiet, 1 la tinh tong
              RevisedRob: false,
              Office: false, //co bao gom du lieu office khong
            }
          : props.value, //thong tin tieu chi truyen vao tu form cha
      CurrentWorksheet: {
        WorksheetID: 0,
        WorksheetCode: "VoyageData",
      },
      WorkbookConfig: {
        version: 0, //doi ve -1 de reset neu can
        JsonConfig: {},
      },
      gridColumns: [[], [], [], []],
      gridGroups: [[], [], [], []],
      grid_data: [[], [], [], []],

      IsSheetCreated: false, //sheet da tao xong ahy chua
      //ban ghi duoc lua chon trong grid
      rowSelected: undefined, //record trong danh sach chuyen duoc chon
      dataFilled: false, //cho biet du lieu duoc fill hay chua
      cellSelected0: {},
      cellSelected1: {},
      cellSelected2: {},
      cellSelected3: {},
      selectedTab: 0,
      showPreview: false,
      DailyReportID: 0,
      PreviewReport: "", //noi dung report
      gridActiveID0: 0, //dau dau ID dc chon
      gridActiveID1: 1,
      gridActiveID2: 2,
      gridOverview: undefined,
      gridConsump: undefined,
      gridSummary: undefined,
      gridVoyage: undefined,
      hasQuyen: global.hasRoles("R224"),
      QuyenXL: {
        //quyen xu ly canh bao
        PhuTrach1: global.hasRoles("R221") ? 1 : 0, //phu trach chuyen vien
        PhuTrach2: global.hasRoles("R222") ? 2 : 0, //phu trach phong
        PhuTrach3: global.hasRoles("R223") ? 3 : 0, //phu trach lanh dao
      },
      showWarning: false,
    };
    this.state.QuyenXL.PhuTrach = this.state.QuyenXL.PhuTrach3
      ? 3
      : this.state.QuyenXL.PhuTrach2
      ? 2
      : this.state.QuyenXL.PhuTrach1
      ? 1
      : 0;
    this.state.PhuTrach = this.state.after.PhuTrach =
      this.state.QuyenXL.PhuTrach;
    console.log(this.state);
  }

  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    var old = s.after[fieldName];
    var search = fieldName !== "ReportTime1" && fieldName !== "ReportTime2" ;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy" &&
      fieldName !== "RevisedRob" &&
      fieldName !== "VoyageCode" &&
      fieldName !== "Office"
    ) {
      this.setMyState(this.state, () => (search ? this.doSearch() : 0));
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => (search ? this.doSearch() : 0));
    }
  };
  //ghi nhan nhieu gia tri tieu chi cung 1 luc
  saveData = (obj) => {
    var s = this.state;
    s.after = { ...s.after, ...obj };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (obj.Ascending || obj.SortBy || obj.TripCode !== undefined) {
      this.setMyState(s, () => this.doSearch());
    } else {
      this.setMyState(s, () => this.doSearch());
    }
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.loadDanhMuc(() => {
      this.doSearch();
    });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {
    //neu thong tin tau thay doi
    if (this.props.ShipData.ShipID !== prevProps.ShipData.ShipID) {
      //reset data

      this.loadDanhMuc(() => {
        this.doSearch();
      });
    }
  }
  //load du lieu danh muc theo danh sach table
  loadDanhMuc(nextFunc) {
    //load danh muc

    server
      .post("Data/DoRequest", {
        Function: "Proc_DanhMuc_GetByShip",
        ThamSo: {
          ShipID: this.props.ShipData.ShipID,
        },
      })
      .then((response) => {
        //xu ly du lieu de gan' danh sach ngay cho tung trip
        const danhSachNgay = response.DR_VoyageTripDay;
        const danhsachTrip = response.DR_VoyageTrip;
        var firstTrip = undefined;
        danhsachTrip.map((item) => {
          item.DanhSachNgay = [];
          danhSachNgay.map((ngay) => {
            if (ngay.TripID == item.TripID) {
              item.DanhSachNgay.push(ngay);
              ngay.Ngay = moment(ngay.TripDate).format("DD/MM/YYYY");
            }
          });
          if (firstTrip == undefined) {
            firstTrip = item;
          }
          //firstTrip = item; //test
        });
        this.state.DanhSachNgay = firstTrip ? firstTrip.DanhSachNgay : [];
        this.state.NgayChon = firstTrip ? firstTrip.DanhSachNgay[0] : null;
        this.setState(
          {
            DanhMuc: {
              ...response,
            },
            after: {
              ...this.state.after,
              TripCode: firstTrip ? firstTrip.TripCode : "",
              Ngay: this.state.NgayChon ? this.state.NgayChon.TripDate : null,
            },
          },
          () => nextFunc()
        );
      })
      .catch((error) => {
        showError(error);
        nextFunc();
      });

    //thuc hien load config excel
  }
  //khi thay doi sap xep cot
  showToastWarning(CheckRuleID, record) {
    let warning = record.Warning;
    toast.dismiss();
    if (warning) {
      let warnList = [];
      for (var i = 1; i <= 3; i++) {
        var tag = CheckRuleID + "_" + i;
        if (warning[tag]) {
          warnList.push(warning[tag]);
        }
      }
      console.log(record);
      if (warnList && warnList.length > 0) {
        toast(
          <p>
            {warnList.map((warn) => (
              <>
                Cảnh báo mức {warn.WarningLevel}:{" "}
                <strong>{warn.RuleName}</strong> <br />
                1. Giá trị sai lệch: <b>{warn.LeftValue}</b>
                <br />
                2. Trạng thái: <b> {dataStruct.XulyWarnings[warn.Result]}</b>
                {warn.Remark1 ? " (" + warn.Remark + ")" : ""}
                <br />
                3. Người XL: {warn.NguoiXN} <br />
                4. Người duyệt: {warn.NguoiDuyet}
                <br />
                (Check ID: {warn.CheckResultID}) <br />
                <hr />
              </>
            ))}
            (Report ID: {record.DailyReportID} )
          </p>
        );
      }
    }
  }
  //gan data vao tong, chi cac truong so
  TinhTong(tong, data) {
    var keys = Object.keys(tong);
    keys.map((field) => {
      if (field !== "STT") {
        let value1 = tong[field];
        let value2 = data[field];
        if (!isNaN(value2) && value2 !== null) {
          if (isNaN(value1) || value1 == null) {
            tong[field] = value2;
          } else {
            tong[field] = value1 + value2;
          }
        }
      }
    });
    return tong;
  }
  //xoa cac truong cua dong tong so
  ClearTong(tong) {
    if (tong == undefined) {
      return;
    }
    myLib.assignForce({}, tong, [
      "Slip",
      "AvgSpeed",
      "AvgSpeedQD",
      "RpmME",
      "BCA",
      "PowerME",
      "ThoiGianBC",
      "ThoiGian",
      "TimeChanged",
      "ID",
    ]);
    tong.ID = 0;
    tong.MaxColorIndex = 0;
    tong.ThoiDiem2 = tong.STT == 1 ? "Tổng" : "";
    tong.WarningRemain = 0;
    tong.ReportID = undefined;
    var keys = Object.keys(tong);
    keys.map((field) => {
      if (field.startsWith("Rob") || field.startsWith("Check")) {
        tong[field] = null;
      }
    });
    tong.Warning = {};
  }
  //xoa cac thong tin neu la DD dau tien
  Clear_DD(data, force) {
    if (force || data.TimeCode == "DD") {
      myLib.assignForce({}, data, [
        "RunhME",
        "RunhAE",
        "Slip",
        "AvgSpeed",
        "AvgSpeedQD",
        "RpmME",
        "BCA",
        "PowerME",

        "TimeChanged",
      ]);
      
      var keys = Object.keys(data);
      if (this.state.after.TripCode) {
        //neu view theo voycode thi clear DD
        keys.map((field) => {
          if (field.startsWith("Cons")) {
            let value = data[field];
            if (!isNaN(value)) {
              data[field] = null;
            }
          }
        });
      }
    }
  }
  //Thuc hien search
  doSearch = () => {
    try {
      this.setState({
        saving: true,
      });
      server
        .post("Data/DoRequest", {
          Function: "Proc_DR_Voyage_GetDataList_2",
          ThamSo: {
            ShipID: this.props.ShipData.ShipID,
            TripCode: this.state.after.TripCode,
            Ngay: this.state.after.Ngay,
            DataScope: this.state.after.DataScope,
            Data: this.state.after.Data,
            DataFilter: this.state.after.DataFilter,
            RevisedRob: this.state.after.RevisedRob,
            Office: this.state.after.Office,
            ReportTime1: this.state.after.ReportTime1,
            ReportTime2: this.state.after.ReportTime2,
            PhuTrach1: this.state.QuyenXL.PhuTrach1,
            PhuTrach2: this.state.QuyenXL.PhuTrach2,
            PhuTrach3: this.state.QuyenXL.PhuTrach3,
            Warning: this.state.showWarning, //co hien thi warning hay khong
          },
        })
        .then((response) => {
          var listWarnings = response.Warning; //danh sach canh bao cho tung report
          //hien thi du lieu thong so ko bao gom FM
          var list_1 = []; //overview
          var list_2 = []; //consumption
          var tong1 = {};
          var tong2_ship = undefined;
          var tong2_office = undefined;
          var tong2_FM = undefined;
          response.VoyageData.map((d) => {
            d.Warning = {
              QuyenXL: this.state.QuyenXL,
            }; //khoi tao la trong

            if (d.STT === 1) {
              //dong SHIP thi gan' warning
              d.MaxColorIndex = 0; //gia tri max level
              d.WarningRemain = 0; // so luong warning chua xu ly het
              listWarnings.map((item) => {
                if (
                  item.DailyReportID === d.DailyReportID &&
                  item.TimeCode === d.TimeCode
                ) {
                  //1 gia tri canh bao co the co nhieu muc canh bao
                  if (d.Warning[item.CheckRuleID] == undefined) {
                    d.Warning[item.CheckRuleID] = item;
                  }
                  if (item.ColorIndex > d.MaxColorIndex) {
                    d.MaxColorIndex = item.ColorIndex;
                  }
                  d.WarningRemain += item.Result < 5 ? 1 : 0;
                }
              });
            }
            if (d.STT !== 2) {
              if (list_2.length == 0) {
                this.Clear_DD(d, this.state.after.DataFilter === "All");
              }
              list_2.push(d);
            }
            if (
              (d.STT == 1 ||
                d.TimeCode == "NS" ||
                d.TimeCode == "AC" ||
                d.TimeCode == "SN" ||
                d.TimeCode == "FS") &&
              d.STT !== 2
            ) {
              //bo FM va dinh muc khac NS
              if (list_1.length == 0) {
                this.Clear_DD(d, this.state.after.DataFilter === "All");
                tong1 = myLib.cloneObject(d);
              } else {
                this.TinhTong(tong1, d);
              }
              list_1.push(d);
            }

            //tinh cac tong
            if (d.STT == 1) {
              tong2_ship =
                tong2_ship == undefined
                  ? myLib.cloneObject(d)
                  : this.TinhTong(tong2_ship, d);
              //console.log("TInh tong", d.ConsFO ? d.ConsFO : d);
            }
            if (d.STT == 2) {
              tong2_FM =
                tong2_FM == undefined
                  ? myLib.cloneObject(d)
                  : this.TinhTong(tong2_FM, d);
            }
            if (d.STT == 3) {
              tong2_office =
                tong2_office == undefined
                  ? myLib.cloneObject(d)
                  : this.TinhTong(tong2_office, d);
            }
          });
          if (list_1.length > 0) {
            list_1.push(tong1);
          }
          if (list_2.length > 0) {
            if (tong2_ship) {
              list_2.push(tong2_ship);
              this.ClearTong(tong2_ship);
            }
            if (tong2_office) {
              list_2.push(tong2_office);
              this.ClearTong(tong2_office);
            }
          }
          this.ClearTong(tong1);
          //tinh them cac chi so trung binh cua overview: tong1
          //dem so dong co PowerME
          var countME = 0,
            totalSlip = 0,
            totalPowerME = 0,
            totalBCA = 0,
            totalSpeed = 0,
            totalSpeedQD = 0,
            totalRPM = 0,
            totalDistance = 0,
            hoursME = 0;
          list_1.forEach((item) => {
            if (item.STT == 1 && !(item.PowerME == null || item.PowerME == 0)) {
              countME++;
              totalSlip += item.Slip ? item.Slip : 0;
              totalPowerME += item.PowerME ? item.PowerME : 0;
              totalBCA += item.BCA ? item.BCA : 0;
              totalSpeed += item.AvgSpeed ? item.AvgSpeed : 0;
              totalSpeedQD += item.AvgSpeedQD ? item.AvgSpeedQD : 0;
              totalRPM += item.RpmME ? item.RpmME : 0;
              totalDistance += item.Distance ? item.Distance : 0;
              hoursME += item.RunhME ? item.RunhME : 0;
            }
          });
          if (countME > 0) {
            tong1.Slip = myLib.round(totalSlip / countME, 2);
            tong1.PowerME = myLib.round(totalPowerME / countME, 2);
            tong1.BCA = myLib.round(totalBCA / countME, 2);
            tong1.RpmME = myLib.round(totalRPM / countME, 2);
            tong1.AvgSpeed = myLib.round(totalDistance / hoursME, 1);
            tong1.AvgSpeedQD = myLib.round(totalSpeedQD / countME, 1);
            //console.log(totalDistance, hoursME);
          }
          if (tong2_ship) {
            response.VoyageData.push(tong2_ship);
          }
          if (tong2_FM) {
            response.VoyageData.push(tong2_FM);
          }
          if (tong2_office) {
            response.VoyageData.push(tong2_office);
          }
          //hien thi du lieu theo ngay
          this.setState({
            saving: false,
            dataFilled: true,
            gridColumns: [
              gridColumns_overview,
              gridColumns_TieuThu,
              gridColumns_Summary,
              gridColumns_Voyage,
            ],
            gridGroups: [
              gridColumns_overview_groups,
              gridColumns_TieuThu_groups,
              gridColumns_Summary_groups,
              [],
            ],
            grid_data: [list_1, list_2, response.VoyageData, response.TripList],
          });
          console.log(list_1, list_2, response.VoyageData);
        })
        .catch((error) => {
          this.setState({ saving: false });
          showError(error);
        });
    } catch (ex) {
      showError(ex);
    }
  };
  loadPreview() {
    //load de hien thi preview report
    server
      .post("Data/DoRequest", {
        Function: "Proc_DailyReport_Get_PreviewAndChecks",
        ThamSo: {
          DailyReportID: this.state.DailyReportID,
        },
      })
      .then((response) => {
        this.setState({
          PreviewReport:
            response.ReportContents.length > 0
              ? response.ReportContents[0].PreviewText
              : "",
        });
      })
      .catch((error) => {
        showError(error);
      });
  }
  doExport() {
    const SEPARATOR = ",";
    //xuat grid ra file excel
    let tab = this.state.selectedTab;
    let grid =
      tab == 0
        ? this.allGrids["Overview"]
        : tab == 1
        ? this.allGrids["Consumption"]
        : tab == 2
        ? this.allGrids["Summary"]
        : this.allGrids["Voyage"];
    const columns = grid.current.visibleColumns;

    const header = columns.map((c) => c.name).join(SEPARATOR);
    const rows = grid.current.data.map((data) =>
      columns.map((c) => data[c.id]).join(SEPARATOR)
    );

    const contents = [header].concat(rows).join("\n");
    const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });
    const fileName = this.state.after.TripCode
      ? this.state.after.TripCode.replaceAll("/", "-")
      : "file";
    downloadBlob(blob, fileName + ".csv");
  }
  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { classes, theme, parentHeight } = this.props;
    //console.log('render................');
    return (
      <div>
        {this.state.showPreview ? (
          <div
            style={{
              position: "absolute",
              top: -65,
              left: 0,
              height: 190,
              width: "60%",
              zIndex: 1000000,
              backgroundColor: "rgba(252, 186, 3, 0.9)",
              overflow: "scroll",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html:
                  this.state.PreviewReport.replaceAll("\r", "<br />") +
                  (this.state.rowSelected
                    ? "Checking RO: FO = " +
                      formatNumber(this.state.rowSelected.CheckFO, 2) +
                      " / DO = " +
                      formatNumber(this.state.rowSelected.CheckDO, 2) +
                      " / Cyl = " +
                      formatNumber(this.state.rowSelected.CheckCyl, 2) +
                      " / Sys = " +
                      formatNumber(this.state.rowSelected.CheckSys, 2) +
                      " / AE = " +
                      formatNumber(this.state.rowSelected.CheckAE, 2) +
                      " / Hyd = " +
                      formatNumber(this.state.rowSelected.CheckHyd, 2)
                    : ""),
              }}
            />
          </div>
        ) : null}

        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.TripCode}</FormLabel>
            </Grid>
            <Grid item xs={4}>
              <MySelectFilter
                id={"TripCode"}
                key={"TripCode"}
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DR_VoyageTrip}
                optionLabel="TripCode"
                optionValue="TripCode"
                placeholder=""
                value={this.state.after}
                onChange={(data, record) => {
                  this.setState(
                    {
                      DanhSachNgay: record ? record.DanhSachNgay : [],
                    },
                    () => this.saveData(data)
                  );
                }}
              ></MySelectFilter>
            </Grid>
            <Grid item xs={1}>
              <FormLabel>{fieldLabels.ReportTime}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                id="ReportTime1"
                key="ReportTime1"
                fullWidth
                value={this.state.after.ReportTime1}
                onChange={(value) => {
                  if (value) {
                    if (this.state.after.ReportTime2 == null) {
                      this.state.after.ReportTime2 = value;
                    }
                    this.state.after.TripCode = null;
                  }
                  this.saveField("ReportTime1", value);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <FormLabel>{">>"}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                id="ReportTime2"
                key="ReportTime2"
                fullWidth
                value={this.state.after.ReportTime2}
                onChange={(value) => {
                  this.saveField("ReportTime2", value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                label="Revised Consumption"
                labelPlacement="end"
                fullWidth
                control={
                  <Checkbox
                    size="medium"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                    checked={this.state.after.RevisedRob}
                    onChange={(event) => {
                      this.saveField("RevisedRob", event.target.checked);
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                label="Office"
                labelPlacement="end"
                fullWidth
                control={
                  <Checkbox
                    size="medium"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                    checked={this.state.after.Office}
                    onChange={(event) => {
                      this.saveField("Office", event.target.checked);
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                label="Warning"
                labelPlacement="end"
                fullWidth
                control={
                  <Checkbox
                    size="medium"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                    checked={this.state.showWarning}
                    onChange={(event) => {
                      this.setState({ showWarning: event.target.checked });
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                label="Report"
                labelPlacement="end"
                fullWidth
                control={
                  <Checkbox
                    size="medium"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                    checked={this.state.showPreview}
                    onChange={(event) => {
                      this.setState({ showPreview: event.target.checked });
                    }}
                  />
                }
              />
            </Grid>

            <Grid item xs={2}>
              <FormLabel>{fieldLabels.DataScope}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MySelect
                options={danhsach_DataScope}
                value={this.state.after.DataScope}
                optionLabel="label"
                optionValue="value"
                onChange={(event) =>
                  this.saveField("DataScope", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={1}>
              {this.state.saving || this.sheetLoading ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
            </Grid>

            <Grid item xs={3}>
              <Button
                variant="contained"
                color={
                  this.state.after.DataFilter == "AtSea" ? "warning" : "primary"
                }
                fullWidth
                onClick={() => {
                  this.state.after.DataFilter = "AtSea";
                  this.doSearch();
                }}
              >
                {buttonLabels.btnAtSea}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color={
                  this.state.after.DataFilter == "Manu" ? "warning" : "primary"
                }
                fullWidth
                onClick={() => {
                  this.state.after.DataFilter = "Manu";
                  this.doSearch();
                }}
              >
                {buttonLabels.btnManu}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color={
                  this.state.after.DataFilter == "InPort"
                    ? "warning"
                    : "primary"
                }
                fullWidth
                onClick={() => {
                  this.state.after.DataFilter = "InPort";
                  this.doSearch();
                }}
              >
                {buttonLabels.btnInport}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color={
                  this.state.after.DataFilter == "All" ? "warning" : "primary"
                }
                fullWidth
                onClick={() => {
                  this.state.after.DataFilter = "All";
                  this.doSearch();
                }}
              >
                {buttonLabels.btnReportData}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color={"error"}
                fullWidth
                disabled={
                  !this.state.hasQuyen || this.state.rowSelected == undefined
                }
                onClick={() => {
                  //loading
                  this.setState({
                    loadingRevised: true,
                  });
                  server
                    .post("Data/DoRequest", {
                      Function: "Proc_DR_DailyReport_Get",
                      ThamSo: {
                        DailyReportID: this.state.DailyReportID,
                      },
                    })
                    .then((response) => {
                      this.setState({
                        edittingRecord: response.DR_TempReport[0],
                        show_edit_hieuchinh: true,
                        loadingRevised: false,
                      });
                    })
                    .catch((error) => {
                      showError(error);
                      this.setState({
                        loadingRevised: false,
                      });
                    });
                }}
              >
                {this.state.loadingRevised
                  ? "Loading..."
                  : buttonLabels.btnRevisedRob}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color={"error"}
                fullWidth
                disabled={
                  !this.state.PhuTrach ||
                  this.state.warningSelected == undefined
                }
                onClick={() => {
                  this.setState({
                    show_warning_confirm: true,
                  });
                }}
              >
                {buttonLabels.btnWarning}
              </Button>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                color="inherit"
                aria-label="export csv"
                onClick={this.doExport}
                edge="start"
                sx={{
                  marginLeft: 3,
                  padding: 0,
                }}
              >
                <SaveAltOutlinedIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                color="inherit"
                aria-label="data list"
                onClick={() =>
                  this.setState({
                    show_list_data:
                      this.state.rowSelected !== undefined &&
                      this.state.rowSelected.ID,
                  })
                }
                edge="start"
                sx={{
                  marginLeft: 3,
                  padding: 0,
                }}
              >
                <StorageIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <TabContext value={this.state.selectedTab}>
              <TabPanel value={0}>
                <ReactDataGrid
                  handle={(grid) => (this.allGrids["Overview"] = grid)}
                  style={{
                    height: parentHeight - 105,
                    fontSize: 12,
                  }}
                  showColumnMenuTool={false}
                  i18n={englishUI ? undefined : ReactDataGrid_i18n}
                  columns={this.state.gridColumns[this.state.selectedTab]}
                  groups={this.state.gridGroups[this.state.selectedTab]}
                  pagination={false}
                  cellSelection={this.state.cellSelected0}
                  multiSelect={true}
                  onCellSelectionChange={(cell) => {
                    let keys = Object.keys(cell);
                    var record = undefined;
                    var column = undefined;
                    if (keys.length > 0) {
                      let key0 = keys[0];
                      let ps = key0.split(",");
                      let ID = parseInt(ps[0]);
                      column = ps[1];
                      this.state.gridActiveID0 = ID;
                      record = this.state.grid_data[
                        this.state.selectedTab
                      ].find((item) => item.ID == ID);

                      if (
                        this.state.showPreview &&
                        record.DailyReportID !== this.state.DailyReportID
                      ) {
                        this.state.DailyReportID = record.DailyReportID;
                        this.loadPreview();
                      }
                    } else {
                      return;
                    }
                    var warning = undefined;
                    var ruleID = undefined;

                    if (
                      record &&
                      column !== undefined &&
                      record.Warning &&
                      warningColumns[column]
                    ) {
                      ruleID = warningColumns[column];
                      if (
                        record.Warning[ruleID + "_1"] !== undefined ||
                        record.Warning[ruleID + "_2"] !== undefined ||
                        record.Warning[ruleID + "_3"] !== undefined
                      ) {
                        if (this.state.showWarning) {
                          this.showToastWarning(ruleID, record);
                        }
                      }
                    }
                    //console.log(record, warning);
                    /*var cell1 = {};
                    cell1[this.state.gridActiveID + ",ThoiDiem2"] = true;
                    var cell2 = {};
                    cell2[this.state.gridActiveID + ",ThoiDiem2"] = true;*/
                    this.setState({
                      selectedRuleID: ruleID,
                      warningSelected: ruleID,
                      cellSelected0: cell,
                      //cellSelected1: cell1,
                      //cellSelected2: cell2,
                      rowSelected0: record,
                      rowSelected: record,
                    });
                  }}
                  showHoverRows={false}
                  dataSource={this.state.grid_data[this.state.selectedTab]}
                  idProperty="ID"
                  rowHeight={30}
                  allowUnsort={false}
                  emptyText={loadDataError(
                    this.state.search_error,
                    this.state.search_result.data.length === 0,
                    this.state.errorMessage,
                    this.state.errorLog
                  )}
                  skipLoadOnMount={false}
                  onSelectionChange={({ data, selected, unselected }) => {}}
                  onReady={(grid) => {
                    this.setState({ gridConsump: grid }, () => {
                      if (this.state.gridActiveID0 > 0) {
                        window.setTimeout(() => {
                          grid.current.scrollToId(this.state.gridActiveID0);
                        }, 100);
                      }
                    });
                  }}
                  activeIndex={this.state.gridActiveIndex}
                  onActiveIndexChange={(act) => {
                    console.log(act);
                    this.setState({ gridActiveIndex: act });
                  }}
                  onCellClick={(event, cellProps) => {
                    var now = new Date().getTime();
                    var diff = now - this.lastClick;
                    if (diff < 400) {
                      var column = cellProps.id;
                      console.log(this.state.rowSelected, cellProps);
                      if (this.state.selectedRuleID !== undefined) {
                        this.setState({
                          show_warning_confirm:
                            this.state.selectedRuleID !== undefined,
                        });
                      } else {
                        if (column === "ThoiDiem2") {
                          this.setState({
                            show_warning_list: true,
                          });
                        }
                      }
                    }
                    this.lastClick = now;
                  }}
                ></ReactDataGrid>
              </TabPanel>
              <TabPanel value={1}>
                <div style={{ flex: "1", width: "100%", height: "100%" }}>
                  <ReactDataGrid
                    handle={(grid) => (this.allGrids["Consumption"] = grid)}
                    style={{
                      height: parentHeight - 105,
                      fontSize: 12,
                    }}
                    showColumnMenuTool={false}
                    i18n={englishUI ? undefined : ReactDataGrid_i18n}
                    columns={this.state.gridColumns[this.state.selectedTab]}
                    groups={this.state.gridGroups[this.state.selectedTab]}
                    pagination={false}
                    cellSelection={this.state.cellSelected1}
                    multiSelect={true}
                    onCellSelectionChange={(cell) => {
                      let keys = Object.keys(cell);
                      var record = undefined;
                      var column = undefined;
                      if (keys.length > 0) {
                        let key0 = keys[0];
                        let ps = key0.split(",");
                        let ID = parseInt(ps[0]);
                        column = ps[1];
                        this.state.gridActiveID0 = ID;
                        record = this.state.grid_data[
                          this.state.selectedTab
                        ].find((item) => item.ID == ID);

                        if (
                          this.state.showPreview &&
                          record.DailyReportID !== this.state.DailyReportID
                        ) {
                          this.state.DailyReportID = record.DailyReportID;
                          this.loadPreview();
                        }
                      } else {
                        return;
                      }
                      var warning = undefined;
                      var ruleID = undefined;

                      if (
                        record &&
                        column !== undefined &&
                        record.Warning &&
                        warningColumns[column]
                      ) {
                        ruleID = warningColumns[column];
                        if (
                          record.Warning[ruleID + "_1"] !== undefined ||
                          record.Warning[ruleID + "_2"] !== undefined ||
                          record.Warning[ruleID + "_3"] !== undefined
                        ) {
                          if (this.state.showWarning) {
                            this.showToastWarning(ruleID, record);
                          }
                        }
                      }
                      /*var cell0 = {};
                      cell0[this.state.gridActiveID + ",ThoiDiem2"] = true;
                      var cell2 = {};
                      cell2[this.state.gridActiveID + ",ThoiDiem2"] = true;*/
                      this.setState({
                        selectedRuleID: ruleID,
                        warningSelected: ruleID,
                        //cellSelected0: cell0,
                        cellSelected1: cell,
                        //cellSelected2: cell2,
                        rowSelected1: record,
                        rowSelected: record,
                      });
                    }}
                    showHoverRows={false}
                    dataSource={this.state.grid_data[this.state.selectedTab]}
                    idProperty="ID"
                    rowHeight={30}
                    allowUnsort={false}
                    emptyText={loadDataError(
                      this.state.search_error,
                      this.state.search_result.data.length === 0,
                      this.state.errorMessage,
                      this.state.errorLog
                    )}
                    skipLoadOnMount={false}
                    onSelectionChange={({ data, selected, unselected }) => {}}
                    onReady={(grid) => {
                      this.setState({ gridConsump: grid }, () => {
                        if (this.state.gridActiveID1 > 0) {
                          window.setTimeout(() => {
                            grid.current.scrollToId(this.state.gridActiveID1);
                          }, 100);
                        }
                      });
                    }}
                    activeIndex={this.state.gridActiveIndex}
                    onActiveIndexChange={(act) => {
                      console.log(act);
                      this.setState({ gridActiveIndex: act });
                    }}
                    onCellClick={(cell) => {
                      var now = new Date().getTime();
                      var diff = now - this.lastClick;
                      if (diff < 400) {
                        cell = this.state.cellSelected1;
                        let keys = Object.keys(cell);
                        var record = undefined;
                        var column = undefined;
                        if (keys.length > 0) {
                          let key0 = keys[0];
                          let ps = key0.split(",");
                          let ID = parseInt(ps[0]);
                          column = ps[1];
                        }
                        console.log(this.state.selectedRuleID);
                        if (this.state.selectedRuleID !== undefined) {
                          this.setState({
                            show_warning_confirm:
                              this.state.selectedRuleID !== undefined,
                          });
                        } else {
                          if (column === "ThoiDiem2") {
                            console.log(this.props.ShipData);
                            this.setState({
                              show_warning_list: true,
                            });
                          }
                        }
                      }
                      this.lastClick = now;
                    }}
                  ></ReactDataGrid>
                </div>
              </TabPanel>
              <TabPanel value={2}>
                <ReactDataGrid
                  handle={(grid) => (this.allGrids["Summary"] = grid)}
                  style={{
                    height: parentHeight - 105,
                    fontSize: 12,
                  }}
                  showColumnMenuTool={false}
                  i18n={englishUI ? undefined : ReactDataGrid_i18n}
                  columns={this.state.gridColumns[this.state.selectedTab]}
                  groups={this.state.gridGroups[this.state.selectedTab]}
                  pagination={false}
                  cellSelection={this.state.cellSelected2}
                  multiSelect={true}
                  renderRowContextMenu1={(menuProps, { rowProps }) => {
                    //console.log(rowProps);
                    let robs = this.state.rowSelected
                      ? "Checking ROB: FO = " +
                        formatNumber(this.state.rowSelected.CheckFO, 2) +
                        " / DO = " +
                        formatNumber(this.state.rowSelected.CheckDO, 2) +
                        " / Cyl = " +
                        formatNumber(this.state.rowSelected.CheckCyl, 2) +
                        " / Sys = " +
                        formatNumber(this.state.rowSelected.CheckSys, 2) +
                        " / AE = " +
                        formatNumber(this.state.rowSelected.CheckAE, 2) +
                        " / Hyd = " +
                        formatNumber(this.state.rowSelected.CheckHyd, 2)
                      : "";
                    menuProps.autoDismiss = true;
                    menuProps.items = [
                      {
                        label:
                          rowProps.data.DailyReportID ==
                          this.state.DailyReportID
                            ? robs
                            : "[empty]",
                      },
                    ];
                  }}
                  onCellSelectionChange={(cell) => {
                    let keys = Object.keys(cell);
                    var record = undefined;
                    var column = undefined;
                    if (keys.length > 0) {
                      let key0 = keys[0];
                      let ps = key0.split(",");
                      let ID = parseInt(ps[0]);
                      column = ps[1];
                      this.state.gridActiveID0 = ID;
                      record = this.state.grid_data[
                        this.state.selectedTab
                      ].find((item) => item.ID == ID);

                      if (
                        this.state.showPreview &&
                        record.DailyReportID !== this.state.DailyReportID
                      ) {
                        this.state.DailyReportID = record.DailyReportID;
                        this.loadPreview();
                      }
                    } else {
                      return;
                    }
                    var warning = undefined;
                    var ruleID = undefined;

                    if (
                      record &&
                      column !== undefined &&
                      record.Warning &&
                      warningColumns[column]
                    ) {
                      ruleID = warningColumns[column];
                      if (
                        record.Warning[ruleID + "_1"] !== undefined ||
                        record.Warning[ruleID + "_2"] !== undefined ||
                        record.Warning[ruleID + "_3"] !== undefined
                      ) {
                        if (this.state.showWarning) {
                          this.showToastWarning(ruleID, record);
                        }
                      }
                    }
                    /*
                    var cell0 = {};
                    cell0[this.state.gridActiveID + ",ThoiDiem2"] = true;
                    var cell1 = {};
                    cell1[this.state.gridActiveID + ",ThoiDiem2"] = true;*/
                    this.setState({
                      selectedRuleID: ruleID,
                      warningSelected: ruleID,
                      //cellSelected0: cell0,
                      //cellSelected1: cell1,
                      cellSelected2: cell,
                      rowSelected2: record,
                      rowSelected: record,
                    });
                  }}
                  showHoverRows={false}
                  dataSource={this.state.grid_data[this.state.selectedTab]}
                  idProperty="ID"
                  rowHeight={30}
                  allowUnsort={false}
                  emptyText={loadDataError(
                    this.state.search_error,
                    this.state.search_result.data.length === 0,
                    this.state.errorMessage,
                    this.state.errorLog
                  )}
                  skipLoadOnMount={false}
                  onSelectionChange={({ data, selected, unselected }) => {
                    console.log(data);
                  }}
                  onReady={(grid) => {
                    this.setState({ gridSummary: grid }, () => {
                      if (this.state.gridActiveID2 > 0) {
                        window.setTimeout(() => {
                          grid.current.scrollToId(this.state.gridActiveID2);
                        }, 100);
                      }
                    });
                  }}
                  onCellClick={(cell) => {
                    var now = new Date().getTime();
                    var diff = now - this.lastClick;
                    if (diff < 400) {
                      cell = this.state.cellSelected2;
                      let keys = Object.keys(cell);
                      var record = undefined;
                      var column = undefined;
                      if (keys.length > 0) {
                        let key0 = keys[0];
                        let ps = key0.split(",");
                        let ID = parseInt(ps[0]);
                        column = ps[1];
                      }
                      console.log(this.state.selectedRuleID);
                      if (this.state.selectedRuleID !== undefined) {
                        this.setState({
                          show_warning_confirm:
                            this.state.selectedRuleID !== undefined,
                        });
                      } else {
                        if (column === "ThoiDiem2") {
                          console.log(this.props.ShipData);
                          this.setState({
                            show_warning_list: true,
                          });
                        }
                      }
                    }
                    this.lastClick = now;
                  }}
                ></ReactDataGrid>
              </TabPanel>
              <TabPanel value={3}>
                <ReactDataGrid
                  handle={(grid) => (this.allGrids["Voyage"] = grid)}
                  style={{
                    height: parentHeight - 105,
                    fontSize: 12,
                  }}
                  showColumnMenuTool={false}
                  i18n={englishUI ? undefined : ReactDataGrid_i18n}
                  columns={this.state.gridColumns[this.state.selectedTab]}
                  groups={this.state.gridGroups[this.state.selectedTab]}
                  pagination={false}
                  cellSelection={this.state.cellSelected3}
                  multiSelect={true}
                  onCellSelectionChange={(evt) =>
                    this.setState({
                      cellSelected3: evt,
                    })
                  }
                  showHoverRows={false}
                  dataSource={this.state.grid_data[this.state.selectedTab]}
                  idProperty="ID"
                  rowHeight={30}
                  allowUnsort={false}
                  emptyText={loadDataError(
                    this.state.search_error,
                    this.state.search_result.data.length === 0,
                    this.state.errorMessage,
                    this.state.errorLog
                  )}
                  skipLoadOnMount={false}
                  onSelectionChange={({ data, selected, unselected }) => {
                    console.log(data);
                  }}
                ></ReactDataGrid>
              </TabPanel>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={(event, newValue) => {
                    this.setState({ selectedTab: newValue });
                  }}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Overview" value={0} />
                  <Tab label="Consumption" value={1} />
                  <Tab label="Summary" value={2} />
                  <Tab label="Voyage" value={3} />
                </TabList>
              </Box>
            </TabContext>
          </Grid>
        </Grid>
        <ToastContainer
          position="top"
          autoClose={5000}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={1}
        />
        {this.state.open_code_seach ? (
          <PagingSearchCodeDialog
            open={true}
            DieuKien={this.state.after}
            gridColumns={this.columns_grid}
            close={(record) => {
              this.setState({
                open_code_seach: false,
              });
            }}
          />
        ) : null}

        {this.state.open_generate_code ? (
          <GenerateCodeDialog
            open={true}
            close={(record) => {
              this.setState({
                open_generate_code: false,
              });
            }}
          />
        ) : null}
        {this.state.action_show_form ? (
          <DataEditDialog
            open={true}
            FormName={this.state.action_FormName}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({
                action_show_form: false,
                action_FormName: undefined,
              });
            }}
          />
        ) : null}
        {this.state.open_edit_departure ? (
          <DepartureReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_departure: false });
            }}
          />
        ) : null}
        {this.state.open_edit_noonatsea ? (
          <NoonAtSeaReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_noonatsea: false });
            }}
          />
        ) : null}
        {this.state.open_edit_arrival ? (
          <ArrivalReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_arrival: false });
            }}
          />
        ) : null}
        {this.state.open_edit_allfast ? (
          <AllfastReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_allfast: false });
            }}
          />
        ) : null}
        {this.state.open_edit_noonport ? (
          <NoonAtPortReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_noonport: false });
            }}
          />
        ) : null}
        {this.state.open_edit_shifting ? (
          <ShiftingReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_shifting: false });
            }}
          />
        ) : null}
        {this.state.show_edit_Portlog ? (
          <PortLogEditDialog
            open={true}
            ShipData={this.props.ShipData}
            defaultTripCode={this.state.after.TripCode}
            DanhMuc={this.state.DanhMuc}
            close={() => {
              this.setState({ show_edit_Portlog: false });
            }}
          />
        ) : null}
        {this.state.show_edit_hieuchinh ? (
          <RevisedRobDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ show_edit_hieuchinh: false });
            }}
          />
        ) : null}
        {this.state.show_warning_confirm &&
        this.state.rowSelected &&
        !myLib.isEmpty(this.state.selectedRuleID) &&
        this.state.rowSelected.ReportID ? (
          <WarningConfirmDialog
            open={true}
            edittingRecord={this.state.rowSelected}
            CheckRule={this.state.selectedRuleID}
            QuyenXL={this.state.QuyenXL}
            close={(ketqua) => {
              if (ketqua) {
                ketqua.map((w) => {
                  this.state.rowSelected.Warning[w.CheckRuleID] = w;
                });
              }
              this.setState({
                show_warning_confirm: false,
                selectedRuleID: undefined,
              });
            }}
          />
        ) : null}
        {this.state.show_warning_list &&
        this.state.rowSelected.ReportID !== undefined ? (
          <WarningListDialog
            open={true}
            ShipData={this.props.ShipData}
            edittingRecord={this.state.rowSelected}
            QuyenXL={this.state.QuyenXL}
            close={(ketqua) => {
              this.setState({
                show_warning_list: false,
              });
            }}
          />
        ) : null}
        {this.state.show_list_data ? (
          <ListReportDataDialog
            open={true}
            edittingRecord={this.state.rowSelected}
            close={(ketqua) => {
              this.setState({
                show_list_data: false,
              });
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  VoyageDataInGridPage
);
