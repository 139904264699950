import { FormLabel } from "@mui/material";
import moment from "moment";
import NumericFormat from "react-number-format";

const myLib = require("../lib/MyLib");
const numberFormatter = Intl.NumberFormat("en-US");

const ROW_FM_BK_COLOR = "#e4f0e7";
const ROW_OFFICE_BK_COLOR = "#e4f0e7";
const ROW_SHIP_BK_COLOR = "#ffffff";

export function formatNumber(num, decimal) {
  return num !== undefined && num !== null && num !== 0
    ? num.toLocaleString("en-US", {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      })
    : null;
}

const styleRed = {
  color: "red",
};

const gridColumns_overview_onRender = (cellProps, { data }) => {
  if (cellProps.style.background === undefined) {
    cellProps.style.background =
      data.STT === 2
        ? ROW_FM_BK_COLOR
        : data.STT === 3
        ? ROW_OFFICE_BK_COLOR
        : ROW_SHIP_BK_COLOR;
  }
  cellProps.style.border = "solid #e4e3e2 1px";
};

//danh sach ten cac column co canh bao
export const warningColumns = {
  Slip: "CheckSlip",
  AvgSpeed: "AvgSpeed",
  AvgSpeedQD: "AvgSpeedQD",
  RpmME: "RpmME",
  ConsFOME24h: "ConsFODOME24h",
  ConsDOME24h: "ConsFODOME24h",
  ConsFOAE24h: "ConsFODOAE24h",
  ConsDOAE24h: "ConsFODOAE24h",
  ConsFOBLR: "ConsFODOBLR",
  ConsDOBLR: "ConsFODOBLR",
  ConsCyl: "ConsCyl",
  ConsCyl24h: "ConsCyl24h",
  ConsSys: "ConsSys",
  ConsSys24h: "ConsSys24h",
  ConsAE: "ConsAE",
  ConsAE24h: "ConsAE24h",
  ConsHyd: "ConsHyd",
  ConsFODOME: "ConsFODOME24h",
  ConsFODOAE: "ConsFODOAE24h",
  ConsFODOBLR: "ConsFODOBLR",
  RobFO: "CheckRobFO",
  RobDO: "CheckRobDO",
  RobCyl: "CheckRobCyl",
  RobSys: "CheckRobSys",
  RobSump: "CheckRobSys",
  RobAE: "CheckRobAE",
  RobHyd: "CheckRobHyd",
};
//tra ve mau sac theo warning
//lanh dao chi thay cap phong + lanh dao
//cap phong chi thay cap phong + chuyen vien
//chuyen vien chi thay cua chuyen vien
function summaryColors_Warning(warning, QuyenXL) {
  let temp =
    warning.WarningLevel === 3
      ? 3
      : warning.WarningLevel === 2
      ? 2
      : warning.WarningLevel === 1
      ? 1
      : 0;
  //console.log(w, temp, warning.WarningLevel);
  return temp;
}
//cho biet warning duoc xu ly hay chua
function style_Warning(warning, QuyenXL) {
  return warning.Result < 5 ? 0 : 1;
}
//render cac cell
function renderWarningCell(column, cellProps, data) {
  let ruleID = warningColumns[column];
  if (ruleID) {
    //tim 3 loai tuong ung
    var findWarn = undefined;
    for (var i = 3; i >= 1; i--) {
      var tag = ruleID + "_" + i;
      if (data.Warning[tag] !== undefined) {
        findWarn = data.Warning[tag];
        break;
      }
    }
    if (findWarn) {
      //console.log(data.AvgSpeed, findWarn);
      cellProps.style.color =
        summaryColors[summaryColors_Warning(findWarn, data.Warning.QuyenXL)];
      if (cellProps.style.color) {
        cellProps.style.fontWeight = "bolder";
        cellProps.style.background =
          confirmWarningColors[style_Warning(findWarn, data.Warning.QuyenXL)];
      }
    }
  }
  gridColumns_overview_onRender(cellProps, { data });
}
////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
export const gridColumns_overview = [
  {
    defaultLocked: "start",
    name: "ThoiDiem2",
    type: "string",
    group: "BaoCao",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 80,
    header: "Thời gian",
    onRender: (cellProps, { data }) => {
      cellProps.style.color = summaryColors[data.MaxColorIndex];
      if (cellProps.style.color) {
        cellProps.style.fontWeight = "bolder";
        cellProps.style.background =
          confirmWarningColorsRow[data.WarningRemain > 0 ? 1 : 0];
      }
      gridColumns_overview_onRender(cellProps, { data });
    },
  },
  {
    defaultLocked: true,
    name: "ThoiGian",
    type: "string",
    group: "BaoCao",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 100,
    header: "Thời điểm B/C",
    onRender: gridColumns_overview_onRender,
  },

  {
    defaultLocked: true,
    name: "NguonDuLieu",
    type: "string",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 80,
    header: "Dữ liệu",
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "TimeChanged",
    type: "string",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 80,
    header: "Múi giờ",
    render: undefined,
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "RunhME",
    type: "number",
    header: "ME",
    group: "Runh",

    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.RunhME, 1),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "RunhAE",
    header: "GE",
    group: "Runh",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.RunhAE, 1),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "RunhPP1",
    header: "PP1",
    group: "Runh",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.RunhPP1, 1),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "RunhPP2",
    header: "PP2",
    group: "Runh",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.RunhPP2, 1),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "RunhIG",
    header: "IGG",
    group: "Runh",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.RunhIG, 1),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "Distance",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 90,
    header: "Distance",
    render: ({ data }) => formatNumber(data.Distance, 1),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "Slip",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 70,
    headder: "Slip",
    onRender: (cellProps, { data }) => {
      let column = "Slip";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "AvgSpeed",
    group: "AvgSpeed",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 80,
    header: "Tốc độ",
    render: ({ data }) => formatNumber(data.AvgSpeed, 1),
    onRender: (cellProps, { data }) => {
      let column = "AvgSpeed";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "AvgSpeedQD",
    group: "AvgSpeed",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 80,
    header: "Quy đổi",
    render: ({ data }) => formatNumber(data.AvgSpeedQD, 1),
    onRender: (cellProps, { data }) => {
      let column = "AvgSpeedQD";
      renderWarningCell(column, cellProps, data);
    },
  },

  {
    name: "RpmME",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 80,
    header: "Rpm.ME",
    render: ({ data }) => formatNumber(data.RpmME, 1),
    onRender: (cellProps, { data }) => {
      let column = "RpmME";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "BCA",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 80,
    header: "Tải",
    render: ({ data }) => formatNumber(data.BCA, 1),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "PowerME",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 80,
    header: "Công suất",
    render: ({ data }) => formatNumber(data.PowerME, 2),
    onRender: gridColumns_overview_onRender,
  },
];
export const gridColumns_overview_groups = [
  { name: "BaoCao", header: "Báo cáo", headerAlign: "center" },
  { name: "Runh", header: "Tổng số giờ chạy", headerAlign: "center" },
  { name: "AvgSpeed", header: "Tốc độ", headerAlign: "center" },
];

export const gridColumns_TieuThu = [
  {
    defaultLocked: true,
    name: "ThoiDiem2",
    type: "string",
    group: "BaoCao",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 80,
    header: "Thời gian",
    render: undefined,
    onRender: gridColumns_overview_onRender,
    onRender: (cellProps, { data }) => {
      cellProps.style.color = summaryColors[data.MaxColorIndex];
      if (cellProps.style.color) {
        cellProps.style.fontWeight = "bolder";
        cellProps.style.background =
          confirmWarningColorsRow[data.WarningRemain > 0 ? 1 : 0];
      }
      gridColumns_overview_onRender(cellProps, { data });
    },
  },
  {
    defaultLocked: true,
    name: "ThoiGian",
    type: "string",
    group: "BaoCao",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 90,
    header: "Thời điểm B/C",
    render: undefined,
    onRender: gridColumns_overview_onRender,
  },

  {
    defaultLocked: true,
    name: "NguonDuLieu",
    type: "string",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 85,
    header: "Dữ liệu",
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ConsFOME",
    type: "number",
    header: "ME",
    group: "ConsFOME",

    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsFOME, 2),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ConsFOME24h",
    type: "number",
    header: "24h",
    group: "ConsFOME",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsFOME24h, 2),
    onRender: (cellProps, { data }) => {
      let column = "ConsFOME24h";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "ConsFOAE",
    type: "number",
    header: "GE",
    group: "ConsFOAE",

    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsFOAE, 2),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ConsFOAE24h",
    type: "number",
    header: "24h",
    group: "ConsFOAE",

    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsFOAE24h, 2),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ConsFOBLR",
    header: "BLR",
    group: "ConsFO",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsFOBLR, 2),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ConsDOME",
    type: "number",
    header: "ME",
    group: "ConsDOME",

    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsDOME, 2),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ConsDOME24h",
    type: "number",
    header: "24h",
    group: "ConsDOME",

    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsDOME24h, 2),

    onRender: (cellProps, { data }) => {
      let column = "ConsDOME24h";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "ConsDOAE",
    type: "number",
    header: "GE",
    group: "ConsDOAE",

    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsDOAE, 2),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ConsDOAE24h",
    type: "number",
    header: "24h",
    group: "ConsDOAE",

    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsDOAE24h, 2),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ConsDOPP",
    header: "PP",
    group: "ConsDO",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsDOPP, 2),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ConsDOIG",
    header: "IGG",
    group: "ConsDO",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsDOIG, 2),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ConsDOBLR",
    header: "BLR",
    group: "ConsDO",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsDOBLR, 2),
    onRender: gridColumns_overview_onRender,
  },

  {
    name: "ConsCyl",
    header: "Cyl",
    group: "ConsCyl",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsCyl, 0),
    onRender: (cellProps, { data }) => {
      let column = "ConsCyl";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "ConsCyl24h",
    header: "24h",
    group: "ConsCyl",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsCyl24h, 0),
    onRender: (cellProps, { data }) => {
      let column = "ConsCyl24h";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "ConsSys",
    header: "Sys",
    group: "ConsSys",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsSys, 0),
    onRender: (cellProps, { data }) => {
      let column = "ConsSys";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "ConsSys24h",
    header: "24h",
    group: "ConsSys",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsSys24h, 0),
    onRender: (cellProps, { data }) => {
      let column = "ConsSys24h";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "ConsAE",
    header: "GE",
    group: "ConsAE",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsAE, 0),
    onRender: (cellProps, { data }) => {
      let column = "ConsAE";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "ConsAE24h",
    header: "24h",
    group: "ConsAE",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsAE24h, 0),
    onRender: (cellProps, { data }) => {
      let column = "ConsAE24h";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "ConsHyd",
    header: "Hyd",
    group: "ConsLO",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsHyd, 0),
    onRender: (cellProps, { data }) => {
      let column = "ConsHyd";
      renderWarningCell(column, cellProps, data);
    },
  },
];
export const gridColumns_TieuThu_groups = [
  { name: "BaoCao", header: "Báo cáo", headerAlign: "center" },
  { name: "ConsFO", header: "Tiêu thụ FO", headerAlign: "center" },
  { name: "ConsFOME", group: "ConsFO", header: "ME", headerAlign: "center" },
  { name: "ConsFOAE", group: "ConsFO", header: "GE", headerAlign: "center" },
  { name: "ConsDO", header: "Tiêu thụ DO", headerAlign: "center" },

  { name: "ConsDOME", group: "ConsDO", header: "ME", headerAlign: "center" },
  { name: "ConsDOAE", group: "ConsDO", header: "GE", headerAlign: "center" },

  { name: "ConsLO", header: "Tiêu thụ LO", headerAlign: "center" },
  { name: "ConsCyl", group: "ConsLO", header: "Cyl", headerAlign: "center" },
  { name: "ConsSys", group: "ConsLO", header: "Sys", headerAlign: "center" },
  { name: "ConsAE", group: "ConsLO", header: "GE", headerAlign: "center" },
];
const summaryColors = [undefined, "blue", "magenta", "red"];
const confirmWarningColors = [undefined, "#b3e6ff", "#b3e6ff", "#b3e6ff"];
const confirmWarningColorsRow = ["#a2c1f5", "#ffffff"];

function summaryColors_FO(lech) {
  lech = Math.abs(lech);
  return lech < 0.5 ? 0 : lech <= 1 ? 1 : lech < 7 ? 2 : 3;
}
function summaryColors_DO(lech) {
  lech = Math.abs(lech);
  return lech < 0.4 ? 0 : lech <= 0.5 ? 1 : lech <= 1.5 ? 2 : 3;
}
function summaryColors_LO(lech) {
  lech = Math.abs(lech);
  return lech < 100 ? 0 : lech <= 200 ? 1 : lech <= 500 ? 2 : 3;
}
function summaryColors_Check(FO, DO, Cyl, Sys, AE, Hyd) {
  var f1 = summaryColors_FO(FO);
  var f2 = summaryColors_DO(DO);
  var f3 = summaryColors_LO(Cyl);
  var f4 = summaryColors_LO(Sys);
  var f5 = summaryColors_LO(AE);
  var f6 = summaryColors_LO(Hyd);
  var f = f1;
  var list = [f1, f2, f3, f4, f5, f6];
  list.map((item) => (f = f < item ? item : f));
  return f;
}

export const gridColumns_Summary = [
  {
    defaultLocked: true,
    name: "ThoiDiem2",
    type: "string",
    group: "BaoCao",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 90,
    header: "Thời gian",

    onRender: (cellProps, { data }) => {
      cellProps.style.color = summaryColors[data.MaxColorIndex];
      if (cellProps.style.color) {
        cellProps.style.fontWeight = "bolder";
        cellProps.style.background =
          confirmWarningColorsRow[data.WarningRemain > 0 ? 1 : 0];
      }
      gridColumns_overview_onRender(cellProps, { data });
    },
  },
  {
    defaultLocked: true,
    name: "ThoiGian",
    type: "string",
    group: "BaoCao",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 100,
    header: "Thời điểm B/C",
    render: undefined,
    onRender: gridColumns_overview_onRender,
  },

  {
    defaultLocked: true,
    name: "NguonDuLieu",
    type: "string",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 80,
    header: "Dữ liệu",
    render: undefined,
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ConsFO",
    type: "number",
    header: "FO",
    group: "Cons",

    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsFO, 2),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ConsDO",
    type: "number",
    header: "DO",
    group: "Cons",

    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsDO, 2),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ConsFODOME",
    header: "ME",
    group: "Cons",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsFODOME, 2),
    onRender: (cellProps, { data }) => {
      let column = "ConsFODOME";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "ConsFODOAE",
    type: "number",
    header: "GE",
    group: "Cons",

    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsFODOAE, 2),
    onRender: (cellProps, { data }) => {
      let column = "ConsFODOAE";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "ConsFODOBLR",
    type: "number",
    header: "BLR",
    group: "Cons",

    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsFODOBLR, 2),
    onRender: (cellProps, { data }) => {
      let column = "ConsFODOBLR";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "ConsFODO2",
    type: "number",
    header: "ME+GE",
    group: "Cons",

    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 100,
    render: ({ data }) => formatNumber(data.ConsFODO2, 2),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ConsFODO3",
    type: "number",
    header: "ALL",
    group: "Cons",

    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 100,
    render: ({ data }) => formatNumber(data.ConsFODO3, 2),
    onRender: gridColumns_overview_onRender,
  },

  {
    name: "ConsFW",
    header: "FW",

    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ConsFW, 2),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "RobFO",
    header: "FO",
    group: "Rob",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.RobFO, 2),
    onRender: (cellProps, { data }) => {
      let column = "RobFO";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "RobDO",
    header: "DO",
    group: "Rob",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.RobDO, 2),
    onRender: (cellProps, { data }) => {
      let column = "RobDO";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "RobCyl",
    header: "Cyl",
    group: "Rob",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.RobCyl, 0),
    onRender: (cellProps, { data }) => {
      let column = "RobCyl";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "RobSys",
    header: "Sys",
    group: "Rob",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.RobSys, 0),
    onRender: (cellProps, { data }) => {
      let column = "RobSys";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "RobAE",
    header: "GE",
    group: "Rob",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.RobAE, 0),
    onRender: (cellProps, { data }) => {
      let column = "RobAE";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "RobHyd",
    header: "Hyd",
    group: "Rob",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    color: "red",
    render: ({ data }) => formatNumber(data.RobHyd, 0),
    onRender: (cellProps, { data }) => {
      let column = "RobHyd";
      renderWarningCell(column, cellProps, data);
    },
  },
  {
    name: "RobSump",
    header: "Sump",
    group: "Rob",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    color: "red",
    render: ({ data }) => formatNumber(data.RobSump, 0),
    onRender: (cellProps, { data }) => {
      let column = "RobSump";
      renderWarningCell(column, cellProps, data);
    },
  },
  //nhan dau FODO
  {
    name: "ReceiveFO",
    header: "FO",
    group: "Receive",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ReceiveFO, 2),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ReceiveDO",
    header: "DO",
    group: "Receive",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ReceiveDO, 2),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ReceiveCyl",
    header: "Cyl",
    group: "Receive",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ReceiveCyl, 0),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ReceiveSys",
    header: "Sys",
    group: "Receive",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ReceiveSys, 0),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ReceiveAE",
    header: "AE",
    group: "Receive",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ReceiveAE, 0),
    onRender: gridColumns_overview_onRender,
  },
  {
    name: "ReceiveHyd",
    header: "Hyd",
    group: "Receive",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 60,
    render: ({ data }) => formatNumber(data.ReceiveHyd, 0),
    onRender: gridColumns_overview_onRender,
  },
];
export const gridColumns_Summary_groups = [
  { name: "BaoCao", header: "Báo cáo", headerAlign: "center" },
  { name: "Cons", header: "Tổng tiêu thụ", headerAlign: "center" },
  { name: "Rob", header: "Lượng tồn", headerAlign: "center" },
  { name: "Receive", header: "Nhận FO/DO/LO", headerAlign: "center" },
];
export const gridColumns_Voyage = [
  {
    name: "TripCode",
    type: "string",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 120,
    header: "Hành trình",
    render: undefined,
  },
  {
    name: "FromPort",
    type: "string",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 120,
    header: "Từ cảng",
    render: undefined,
  },
  {
    name: "TimeDD",
    type: "string",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 120,
    header: "Thời gian DD",
    render: undefined,
  },
  {
    name: "CargoMT",
    type: "string",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 150,
    header: "Hàng hóa",
    render: undefined,
  },
  {
    name: "ToPort",
    type: "string",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 120,
    header: "Đến cảng",
    render: undefined,
  },
  {
    name: "TimeAA",
    type: "string",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 120,
    header: "Thời gian đến",
    render: undefined,
  },
];
