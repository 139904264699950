/**Nhap danh muc chu tau */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";

/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MySelect from "../components/MySelect";
import { DatasetController } from "chart.js";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;

/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Warning confirm" : "Xác nhận xử lý";
const fieldLabels = englishUI
  ? {
      RuleName: "Warning level ",
      LeftValue: "Diff value:",
      ActionGuide: "Action guide:",
      Result1: "Status:",
      Remark1: "Remark:",
      Result2: "Warning level 2:",
      Remark2: "Remark:",
      Result3: "Warning level 3:",
      Remark3: "Remark:",
    }
  : {
      TripCode: "Trip code:",
      ReportDataTime: "Report time:",
      HCRobFO: "Cons - FO",
      HCRobDO: "Cons - DO",
      HCRobCyl: "Cons - Cyl",
      HCRobSys: "Cons - Sys",
      HCRobAE: "Cons - AE",
      HCRobHyd: "Cons - Hyd",
    };
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
    };

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version

  return ret;
}
////////////////////////--------------/////////////////////////////////////////
export default class WarningConfirmDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validateStatus: { error: false }, //trang thai validate
      after: {},
      before: {},
      Warnings: [], //danh sach canh bao
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  //load du lieu khi form load
  loadDanhMuc() {
    //lay ma chuyen tu dong tiep theo
    if (this.props.edittingRecord != undefined) {
      this.setState({ saving: true });
      server
        .post("Data/DoRequest", {
          Function: "Proc_DR_CheckResult_Get",
          ThamSo: {
            ReportID: this.props.edittingRecord.ReportID,
            CheckRule: this.props.CheckRule,
            PhuTrach1: this.props.QuyenXL.PhuTrach1,
            PhuTrach2: this.props.QuyenXL.PhuTrach2,
            PhuTrach3: this.props.QuyenXL.PhuTrach3,
            TimeCode: this.props.edittingRecord.TimeCode,
          },
        })
        .then((response) => {
          //du lieu cua 1 do thi
          let list = response.DR_CheckResult;
          list.map((item) => (item.before = myLib.cloneObject(item)));
          let check = list[0];
          this.setState(
            {
              saving: false,
              Warnings: list,
              hasQuyen:
                (check.WarningLevel == 1 &&
                  this.props.QuyenXL.PhuTrach1 + this.props.QuyenXL.PhuTrach2 >
                    0) ||
                (check.WarningLevel == 2 &&
                  this.props.QuyenXL.PhuTrach1 +
                    this.props.QuyenXL.PhuTrach2 +
                    this.props.QuyenXL.PhuTrach3 >
                    0) ||
                (check.WarningLevel == 3 &&
                  this.props.QuyenXL.PhuTrach1 +
                    this.props.QuyenXL.PhuTrach2 +
                    this.props.QuyenXL.PhuTrach3 >
                    0),
            },
            () => {
              console.log(this.props.QuyenXL, this.state);
            }
          );
          if (list.length == 0) {
            this.props.close();
          }
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({ saving: false });
        });
    }
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var data = this.state.after;
      var check = validate_Data(this.state.after);
      //chuyen doi format

      var action = this.props.edittingRecord ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DR_CheckResult_Save",
            ThamSo: {
              Warnings: this.state.Warnings,
              QuyenXL: this.props.QuyenXL,
            },
          })
          .then((response) => {
            let kq = response.DR_CheckResult;

            //thong bao cho parent biet co data duoc saved
            if (this.props.onSaved) {
              this.props.onSaved(action, kq);
            }
            alert("Cập nhật thành công!");
            this.props.close(kq);
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (ex) {
      showError(ex);
    }
  };

  render() {
    const { classes, theme, parentHeight } = this.props;

    return (
      <Dialog
        open={this.props.open}
        maxWidth={"md"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formTitle}</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              {this.state.Warnings.map((check) => (
                <>
                  <Grid item xs={4}>
                    <FormLabel
                      style={{
                        color: "red",
                        fontWeight: "bold !important",
                      }}
                    >
                      {fieldLabels.RuleName + check.WarningLevel + ":"}
                    </FormLabel>
                  </Grid>
                  <Grid item xs={20}>
                    <OutlinedInput
                      id="RuleName"
                      fullWidth
                      readOnly={true}
                      value={check.RuleName}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel>{fieldLabels.LeftValue}</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <MyNumberEditor
                      id="LeftValue"
                      key="LeftValue"
                      fullWidth
                      readOnly
                      value={check.LeftValue}
                      onChange={(value) => {
                        this.saveField("LeftValue", value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={16}></Grid>
                  <Grid item xs={4}>
                    <FormLabel>{fieldLabels.ActionGuide}</FormLabel>
                  </Grid>
                  <Grid item xs={20}>
                    <OutlinedInput
                      id="ActionGuide"
                      key="ActionGuide"
                      fullWidth
                      multiline
                      readOnly
                      value={check.ActionGuide}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormLabel>{fieldLabels.Result1}</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <MySelect
                      id="Result"
                      options={
                        check.Result
                          ? dataStruct.WarningStatusList2
                          : dataStruct.WarningStatusList1
                      }
                      optionLabel="Label"
                      optionValue="Result"
                      value={check.Result}
                      isClearable
                      onChange={(event) => {
                        var value = event.target.value;
                        console.log(value, check, this.props.QuyenXL);
                        if (value === 5) {
                          //5 la da duyet

                          if (
                            check.WarningLevel == 1 &&
                            this.props.QuyenXL.PhuTrach1 == 0
                          ) {
                            //ko co quyen duyet
                            value = check.before.Result;
                          }
                          if (
                            check.WarningLevel == 2 &&
                            this.props.QuyenXL.PhuTrach2 == 0
                          ) {
                            //ko co quyen duyet
                            value = check.before.Result;
                          }
                          if (
                            check.WarningLevel == 3 &&
                            this.props.QuyenXL.PhuTrach3 == 0
                          ) {
                            //ko co quyen duyet
                            value = check.before.Result;
                          }
                        } else {
                          if (
                            check.before.Result === 5 &&
                            this.props.QuyenXL.PhuTrach2 === 0 &&
                            check.WarningLevel == 1
                          ) {
                            //ko co quyen huy duyet
                            value = 5;
                          } else if (
                            check.before.Result === 5 &&
                            this.props.QuyenXL.PhuTrach1 === 0 &&
                            check.WarningLevel == 2
                          ) {
                            //ko co quyen huy duyet
                            value = 5;
                          }
                        }
                        check.Result = value;
                        this.setState({ saving: false });
                      }}
                    ></MySelect>
                  </Grid>
                  <Grid item xs={2}>
                    <FormLabel>{fieldLabels.Remark1}</FormLabel>
                  </Grid>
                  <Grid item xs={14}>
                    <OutlinedInput
                      id="Remark1"
                      key="Remark1"
                      fullWidth
                      multiline
                      readOnly={
                        check.before.Result === 0 ||
                        !(
                          (this.props.QuyenXL.PhuTrach1 &&
                            check.before.Result < 5) ||
                          (this.props.QuyenXL.PhuTrach2 &&
                            check.before.Result >= 4)
                        )
                      }
                      value={check.Remark}
                      onChange={(event) => {
                        check.Remark = event.target.value;
                        this.setState({ saving: false });
                      }}
                    />
                  </Grid>
                  <Grid item xs={24}>
                    <hr />
                  </Grid>
                </>
              ))}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
            disabled={this.state.saving || !this.state.hasQuyen}
          >
            {buttonLabels.btnSave}
          </Button>

          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            disabled={this.state.saving}
            variant="contained"
          >
            {buttonLabels.btnClose}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
